<template>
  <div class="footer">
    <div class="footer-t">
      <div class="content-inner">
        <img src="../assets/images/logo.-2png.png">
        <ul>
          <li><router-link to="/">首 页</router-link></li>
          <li><router-link to="/about">关于我们</router-link></li>
          <li><router-link to="/news">新闻动态</router-link></li>
          <li><router-link to="/business">业务展示</router-link></li>
          <!--<li><router-link to="/car">车源展示</router-link></li>-->
          <li><router-link to="/contact">联系我们</router-link></li>
        </ul>
        <div class="clear"></div>
      </div>
    </div>
    <div class="footer-m">
      <div class="content-inner">
        <div class="m-l">
          <div class="m-l-t">广州长佶科技有限公司</div>
          <div class="m-l-b">
            公司旨在为客户提供“二手车业务整体解决方案”，经过六年的发展，打造了一套适用于汽车主机厂、经销商集团、特约店的二手车运营模式，帮助4S店提升二手车置换率，促进新车销售。长佶科技与广汽本田合作项目包括二手车业务咨询辅导、网拍平台系统推广和运维、二手车置换业务检核等；人员稳定、经验丰富。
          </div>
        </div>
        <div class="m-m">
          <div class="m-m-t">联系我们</div>
          <ul>
            <li>客服邮箱：crm@ggucar.com</li>
            <li>商务联系：crm@ggucar.com</li>
            <li>商务联系：020-39202331</li>
            <li>联系客服：020-39202331</li>
          </ul>
        </div>
        <ul class="m-r">
          <li>
            <div class="m-r-t">产品与服务</div>
            <img src="../assets/images/qr-code-1.png">
            <div class="m-r-t">竞拍端</div>
          </li>
          <li>
            <div class="m-r-t">&nbsp;</div>
            <img src="../assets/images/qr-code-2.png">
            <div class="m-r-t">管理端</div>
          </li>
          <li class="last">
            <div class="m-r-t">产品与服务</div>
            <img src="../assets/images/qr-code-gzh.jpg">
            <div class="m-r-t">优车优盟公众号</div>
          </li>
          <span>020-39202331</span>
        </ul>
        <div class="clear"></div>
      </div>
    </div>
    <div class="footer-b">
      <div class="content-inner">
        Copyright@2022 All Rights Reserved 广州市长佶科技有限公司版权所有  <a href="http://beian.miit.gov.cn/" target="_blank">粤ICP备16031375号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pageFooter',
  data() {
    return {
    }
  },
  mounted() {
  }
}
</script>


<template>
	<div class="about-page">
    <pageHeader></pageHeader>

    <div class="page-banner"></div>
    <div class="page-menu">
      <div class="content-inner">
        <ul>
          <li><router-link to="/about" class="active">关于我们</router-link></li>
          <li><router-link to="/development">发展历程</router-link></li>
        </ul>
        <div class="breadcrumb">
          <el-breadcrumb separator-class="el-icon-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>关于我们</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="clear"></div>
      </div>
    </div>

    <div class="session session-about-1">
      <div class="content-inner">
        <div class="session-h1">关于我们</div>
        <div class="session-h2">专业二手车交易运营服务商</div>
        <div class="abt-1">广州长佶科技有限公司（以下简称“长佶科技”）成立于2014年12月，至今已有7年发展历史。公司运营的“优 车优盟”是广汽本田主机厂唯一指定二手车交易平台，并与广汽传祺、广汽丰田、广汽三菱等主机厂保持常态化合 作关系。</div>
        <ul>
          <li>
            <span>50+</span>
            <div>公司员工</div>
          </li>
          <li>
            <span>30+</span>
            <div>自主研发软著</div>
          </li>
          <li>
            <span>2000+</span>
            <div>累计服务各品牌4S店</div>
          </li>
          <li class="clear"></li>
        </ul>
        <div class="abt-5">长佶科技共计员工近40名，其中研发团队人数占企业总人数三分之一，拥有独立自主研发软著近30项，并于2018年通过高新技术企业认证。企业旨在2025年将现有研发团队扩充至百人，打造一支在二手车互联 网产品技术领域具有领先地位的产品团队。
        </div>

      </div>
    </div>

    <div class="session session-about-2">
      <div class="content-inner">
        <div class="session-h1">我们的优势</div>
        <div class="abt-1">
          <p>长佶科技业务覆盖全国32个省及自治区，累计服务各品牌4s销售店逾1000家，累计完成销售店置换车成交 量8.2万余台，2020年平台成交量环比增长率达26%。</p>
          <strong>成交量 8.2万余台</strong>
        </div>
        <div class="abt-2">
          <p>7年来，长佶科技深耕二手车置换与线上交易，以专业咨询辅导及“优车优盟”平台服务于各大主机厂、经销 商集团、二手车经销商用户。公司在发展过程中，逐渐形成了以促进主机厂品牌二手车业务升级为核心的服务融合体系。2020年12月，公司获车易拍投资，进一步提升公司互联网二手车数据分析、挖掘、服务网络建设能力， 提高平台市场竞争力。
          </p>
        </div>
        <div class="clear"></div>

      </div>
    </div>

    <!--<div class="session session-about-3">
      <div class="content-inner">
        <div class="session-h1">我们的成长</div>
        <div class="session-h2">专业二手车交易运营服务商</div>

        <ul>
          <li class="l1">
            <img src="../assets/images/about-2.jpg">
          </li>
          <li class="l2">
            <img src="../assets/images/about-3.jpg">
          </li>
          <li class="l3">
            <div>长佶科技将以交易平台累积的二手车交易数据与业务经验为依托，以二手车交易服务为主业、销售店咨询辅导业务为辅的融合服务，建立二手车行业标准化业务运营管理体系，提升置换率与盈利能力，带动二手车行业的标准化建设，助力汽车销售店二手车交易的健康发展。</div>
          </li>
          <li class="l4">
            <img src="../assets/images/about-4.jpg">
          </li>

          <li class="l5">
            <img src="../assets/images/about-5.jpg">
          </li>
          <li class="l6">
            <img src="../assets/images/about-3.jpg">
          </li>
          <li class="clear"></li>
        </ul>

      </div>
    </div>-->




    <pageFooter></pageFooter>







	</div>
</template>

<script>
import pageHeader from "@/components/pageHeader";
import pageFooter from "@/components/pageFooter";

export default {
  components: {pageHeader, pageFooter},
	data() {
		return {
			loading: true,
		};
	},
	mounted() {

	},
	methods: {

	}
};
</script>

<style lang="scss" scoped>
.page-banner{
  height: 520px;
  background-image: url("../assets/images/banner-2.jpg");
}
.session-about-1{
  .abt-1{
    background: #f4f4f4;
    padding: 15px 130px;
    text-align: center;
    margin-top: 50px;
  }
  ul{
    padding-top: 55px;
    padding-bottom: 55px;
    li{
      width: 33.33%;
      float: left;
      text-align: center;
      span{
        font-size: 60px;
        color: #f4a307;
        font-weight: bold;
        display: inline-block;
        position: relative;
        span{
          position: absolute;
          font-weight: normal;
          top: -15px;
          right: -20px;
          font-size: 16px;
          color: #000;
        }
      }
      div{
        margin-top: 5px;
      }
    }
  }
}
.session-about-2{
  background-image: url("../assets/images/banner-3.jpg");
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  .session-h1{
    margin-bottom: 70px;
    position: relative;
    padding-bottom: 30px;
  }
  .session-h1:after {
    position: absolute;
    content: '';
    bottom: 0px;
    left: 50%;
    width: 74px;
    height: 2px;
    margin-left: -37px;
    background-color: #f4a307;
  }
  .abt-1{
    width: 560px;
    float: left;
    strong{
      font-size: 48px;
      color:#f4a307;
      display: inline-block;
      margin-top: 20px;
    }
  }
  .abt-2{
    width: 560px;
    float: right;
  }
}
.session-about-3{
  ul{
    margin-top: 85px;
    li{
      float: left;
      overflow: hidden;
    }
    li.l1{
      width: 234px;
      height: 388px;
      margin-right: 12px;
    }
    li.l2{
      width: 340px;
      height: 186px;
      margin-right: 12px;
      margin-bottom: 12px;
    }
    li.l3{
      width: 600px;
      height: 186px;
      margin-bottom: 12px;
      background: #f4a307;
      font-size: 16px;
      line-height: 30px;
      color: #fff;
      div{
        padding: 30px 20px 0 20px;
      }
    }
    li.l4{
      width: 340px;
      height: 203px;
      margin-right: 12px;
    }
    li.l5{
      width: 294px;
      height: 203px;
      margin-right: 12px;
    }
    li.l6{
      width: 294px;
      height: 203px;
    }
  }
}
</style>

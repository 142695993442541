<template>
  <div class="header">
    <div class="top-bar">
      <div class="content-inner">
        <div class="top-bar-4">020-84811001</div>
        <div class="top-bar-3"></div>
        <div class="top-bar-2" @click="onClickChooseRole">我是{{ roleName }}</div>
        <div class="top-bar-1">欢迎来到广易拍<a href="http://ucarunion.com/user" target="_blank">登录</a></div>
        <div class="clear"></div>
      </div>
    </div>
    <div class="main-menu">
      <div class="content-inner">
        <img src="../assets/images/logo.png">
        <ul>
          <li><router-link :class="type=='home'?'active':''" to="/">首 页</router-link></li>
          <li><router-link :class="type=='about'?'active':''" to="/about">关于我们</router-link></li>
          <li><router-link :class="type=='news'?'active':''" to="/news">新闻动态</router-link></li>
          <li><router-link :class="type=='business'?'active':''" to="/business">业务展示</router-link></li>
          <!--<li><router-link :class="type=='car'?'active':''" to="/car">车源展示</router-link></li>-->
          <li><router-link :class="type=='contact'?'active':''" to="/contact">联系我们</router-link></li>
        </ul>
        <div class="clear"></div>
      </div>
    </div>


    <el-dialog
        title="请选择"
        :visible.sync="isShowChoose"
        width="532px"
        top="10%"
    >
      <div class="choose-roles">
        <div class="choose-roles-item" @click="onSubmitRole(1)">
          <img src="../assets/images/choose-1.jpg">
          <div class="choose-role">我是4s店</div>
        </div>
        <div class="choose-roles-item" @click="onSubmitRole(2)">
          <img src="../assets/images/choose-2.jpg">
          <div class="choose-role">我是车商</div>
        </div>
        <div class="clear"></div>
      </div>
    </el-dialog>


  </div>


</template>

<script>
import cookie from 'vue-cookie'
export default {
  name: 'pageHeader',
  data() {
    return {
      type:'home',
      isShowChoose:false,
      roleName:'我是车商',
    }
  },
  mounted() {
    this.type = this.$route.meta.type
    this.getRoleName()
  },
  methods: {
    onClickCity(city){
      this.filter.city = city
    },
    onClickShow(){
      this.$router.push({ path: '/car/zhanshi/1'})
    },
    getRoleName(){
      let role = cookie.get('user_role')
      if(role==undefined){
        this.onClickChooseRole()
      }
      else if(role==1){
        this.roleName = '4s店'
      }
      else if(role==2){
        this.roleName = '车商'
      }
    },
    onClickChooseRole(){
      this.isShowChoose = true
    },
    onSubmitRole(role){
      cookie.set('user_role', role, {expires:7});
      this.isShowChoose = false
      this.getRoleName()
      this.$router.push({ path: '/business?role='+role})
    }
  }
}
</script>

